import React, { useState } from 'react';
import Slider from 'react-slick';
import unitSlide1 from 'assets/images/unit-slide1.jpg';
import unitSlide1Webp from 'assets/images/unit-slide1.jpg?as=webp';
import unitSlide2 from 'assets/images/unit-slide2.jpg';
import unitSlide2Webp from 'assets/images/unit-slide2.jpg?as=webp';
import LazyLoad from 'react-lazyload';
import { checkWebPSupport } from 'supports-webp-sync';
import loadable from '@loadable/component';
import { useProgressiveImage } from '../../hooks';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <button type='button' className={`unit-carousel__slide-next ${className}`} onClick={onClick}>
      <svg className='icon icon-circle-right-arrow'><use xlinkHref='#icon-circle-right-arrow' /></svg>
      <span className='screen-reader-text'>Slick next button</span>
    </button>
  );
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <button type='button' className={`unit-carousel__slide-prev ${className}`} onClick={onClick}>
      <svg className='icon icon-circle-left-arrow'><use xlinkHref='#icon-circle-left-arrow' /></svg>
      <span className='screen-reader-text'>Slick Prev button</span>
    </button>
  );
};

/**
 * @returns {JSX.Element}
 * @constructor
 */
const UnitSlider = () => {
  const isWebPSupport = checkWebPSupport();
  const placeholder1 = isWebPSupport ? unitSlide1Webp : unitSlide1;
  const loaded1 = useProgressiveImage(isWebPSupport ? unitSlide1Webp : unitSlide1);

  const placeholder2 = isWebPSupport ? unitSlide2Webp : unitSlide2;
  const loaded2 = useProgressiveImage(isWebPSupport ? unitSlide2Webp : unitSlide2);

  const [ slider, setSlider ] = useState(null);

  const goTofirst = () => {
    slider?.slickGoTo(0);
  };
  const afterChangeHandler = (current, next) => {
    if (next === current) {
      setTimeout(() => {
        goTofirst();
      }, 2500);
    }
  };

  const settings = {
    slidesToShow: 1,
    arrows: true,
    dots: false,
    infinite: false,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => afterChangeHandler(1, next),
  };

  return (
    <Slider id='unit-controls' className='unit-carousel support-block' {...settings} ref={currentRef => setSlider(currentRef)}>
      <div className='unit-slide unit-slide--1'>
        <div className='unit-slide__inner' style={{ backgroundImage: `url(${loaded1 || placeholder1})` }}>
          <div className='unit-slide__header'>
            <h2 className='unit-slide__header-subtitle'>Unit controls</h2>
            <h3 className='unit-slide__header-title'>Know what you're looking at</h3>
          </div>
          <figure className='unit-slide__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={unitSlide1Webp} type='image/webp' />
                  <source srcSet={unitSlide1Webp} type='image/jpg' />
                  <img src={unitSlide1} alt='unit-slide1' />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <div className='unit-slide__footer'>
            <h3>Automagic Mode</h3>
            <p>Get perfect air, programmed for you.</p>
          </div>
          <div className='unit-slide__content'>
            <div className='unit-slide__detail-box unit-slide__detail-box--1'>
              <div className='unit-slide__line' />
              <div className='unit-slide__detail-text'>
                <h3>Mode</h3>
                <p>Indicates which main mode you’re in: Manual or Automagic.</p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--2'>
              <div className='unit-slide__detail-text'>
                <h3>Outdoor AQI</h3>
                <p>Your current outdoor air quality, as measured on a scale of 0-300 (lower is better). </p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--3'>
              <div className='unit-slide__line' />
              <div className='unit-slide__detail-text'>
                <h3>Protection Factor</h3>
                <p>Indicates the difference in air quality between indoors and outdoors.</p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--4'>
              <div className='unit-slide__detail-text'>
                <h3>Indoor AQI</h3>
                <p>Your current indoor air quality, as measured on a scale of 0-300 (lower is better).</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='unit-slide unit-slide--2'>
        <div className='unit-slide__inner' style={{ backgroundImage: `url(${loaded2 || placeholder2})` }}>
          <div className='unit-slide__header'>
            <h2 className='unit-slide__header-subtitle'>Unit controls</h2>
            <h3 className='unit-slide__header-title'>Know what you're looking at</h3>
          </div>
          <figure className='unit-slide__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={unitSlide2Webp} type='image/webp' />
                  <source srcSet={unitSlide2} type='image/jpg' />
                  <img src={unitSlide2} alt='unit-slide2' />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <div className='unit-slide__footer'>
            <h3>Manual Mode</h3>
            <p>Get perfect air, programmed for you.</p>
          </div>
          <div className='unit-slide__content'>
            <div className='unit-slide__detail-box unit-slide__detail-box--1'>
              <div className='unit-slide__line' />
              <div className='unit-slide__detail-text'>
                <h3>Mode</h3>
                <p>Indicates which main mode you’re in: Manual or Automagic.</p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--2'>
              <div className='unit-slide__detail-text'>
                <h3>Target AQI</h3>
                <p>Your projected AQI based on fan speed and room size. </p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--3'>
              <div className='unit-slide__line' />
              <div className='unit-slide__detail-text'>
                <h3>Current AQI</h3>
                <p>The current indoor air quality, calculated based on Mila’s sensors.</p>
              </div>
            </div>
            <div className='unit-slide__detail-box unit-slide__detail-box--4'>
              <div className='unit-slide__detail-text'>
                <h3>Air Changes / Hr</h3>
                <p>
                  How frequently air circulates through Mila's filter every hour in a room.
                  The higher your ACH, the higher your fan speed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default UnitSlider;
